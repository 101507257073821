;(function ($, window, document, undefined) {
    $(function () {

        // Allow "hover" on all bootstrap dropdown menus, this is
        // mostly useful for the navbar, but will work elsewhere too

        $.fn.unBindBootstrapNavbarCollapse = function() {

            return this.each(function() {

                var collapse = $(this).find('.navbar-collapse');

                var onMouseIn = function() {
                    $(this).addClass('open');
                };

                var onMouseOut = function() {
                    $(this).removeClass('open');
                };

                collapse.find('.dropdown').hover(onMouseIn, onMouseOut);

                collapse.on('hide.bs.collapse', function() {
                    $(this).find('.dropdown').bind('mouseenter', onMouseIn).bind('mouseleave', onMouseOut);
                }).on('show.bs.collapse', function() {
                    $(this).find('.dropdown').unbind('mouseenter').unbind('mouseleave');
                });

            });
        };

        $('.navbar').unBindBootstrapNavbarCollapse();

        $('.flexslider').flexslider({slideshowSpeed: 4000});



                $('a').hover(function(e){

                    $(this).attr('data-title', $(this).attr('title'));
                    $(this).removeAttr('title');

                },
                function(e){

                    $(this).attr('title', $(this).attr('data-title'));

                });​



    });
})(jQuery, window, document);